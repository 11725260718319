import React, { useMemo, useState } from "react";

import { IconButton } from "@fluentui/react";
import {
  Button,
  Checkbox,
  Flex,
  Text,
  CloseIcon,
  Dialog,
  Loader,
} from "@fluentui/react-northstar";
import { useEffect } from "react";
import moment from "moment";
import { useHistory, useLocation, useParams, Prompt } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BlobServiceClient } from "@azure/storage-blob";
import { withTranslation } from "react-i18next";

import { NotificationAlert } from "../../../../../components/Notification/Notification";

import FeedbackQuestions from "../Components/FeedbackQuestions";
import VideoQuestion from "../Components/VideoQuestion";

import "./index.scss";

const SHARE_WITH_OPTIONS = {
  everyone: "everyone",
  classrooms: "Classrooms",
  group: "Groups",
};

const FEEDBACK_TYPES = {
  all: "all",
  any: "ANY",
  text: "TEXT",
  audio: "AUDIO",
  video: "VIDEO",
  poll: "POLL",
};

const url = process.env.REACT_APP_EP_URL;

const FeedbackForm = (props) => {
  const {
    type,
    user,
    setUser,
    classrooms: classroomsData,
    groups: groupsData,
    isLoadingData,
    setIsLoadingData,
    t,
    setFeedbackLimitDialog,
  } = props;

  const history = useHistory();
  const location = useLocation();

  const [feedbackQuestion, setFeedbackQuestion] = useState("");
  const [selectedShareWithOption, setSelectedShareWithOption] = useState(null);
  const [videoState, setVideoState] = useState({
    isStart: false,
    isSaved: false,
    isDone: false,
    videoUrl: null,
  });
  const [sharedWith, setSharedWith] = useState({
    isEveryoneInTheOrganization: false,
    isParentsInTheOrganization: false,
    classrooms: [],
    groups: [],
  });

  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isAutoLogOutAfterSubmission, setIsAutoLogOutAfterSubmission] =
    useState(false);
  const [description, setDescription] = useState("");

  const [confirmVideoDelete, setConfirmVideoDelete] = useState(false);
  const [isValidateFields, setIsValidateFields] = useState(false);
  const [feedbackData, setFeedbackData] = useState(null);
  const [feedbackType, setFeedbackType] = useState("TEXT");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);

  const [options, setOptions] = useState([
    { option: 1, value: "" },
    { option: 2, value: "" },
    { option: 3, value: "" },
  ]);

  const [questions, setQuestions] = useState([]);
  const [isMixMode, setIsMixMode] = useState(false);
  const [isMultiItemFeedback, setIsMultiItemFeedback] = useState(false);
  const [isChangedForm, setIsChangedForm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.feedbackForm;
  const feedbackTypesTranslation = translation.body.feedbackTypes;

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const handleFeedbackData = (data) => {
    if (data) {
      const feedback = { ...data };
      setFeedbackData(feedback);
      setFeedbackType(data.feedbackType);

      const {
        title,
        isEveryoneInTheOrganization,
        classId,
        groupId,
        videoUrl,
        pollOptions,
        feedbackEndsOn,
        isAutoLogoutAfterSubmission,
        description,
        FeedbackQuestions,
      } = feedback;

      setFeedbackQuestion(title);
      setSelectedDateTime(
        feedbackEndsOn
          ? moment(feedbackEndsOn).isValid()
            ? moment(feedbackEndsOn).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            : null
          : null
      );
      setSharedWith({
        isEveryoneInTheOrganization,
        classrooms: classId?.length
          ? classId.map((classData) => ({
              id: classData,
              students: [],
            }))
          : [],
        groups: groupId?.length
          ? groupId.map((groupData) => ({
              id: groupData,
              students: [],
            }))
          : [],
      });

      setOptions(pollOptions);

      setIsAutoLogOutAfterSubmission(!!isAutoLogoutAfterSubmission);
      setDescription(description);

      setQuestions(
        FeedbackQuestions?.length
          ? FeedbackQuestions.map(({ feedbackQuestionId, ...rest }) => ({
              ...rest,
              id: feedbackQuestionId,
            }))
          : []
      );

      if (!isEveryoneInTheOrganization) {
        if (classId?.length) {
          setSelectedShareWithOption(SHARE_WITH_OPTIONS.classrooms);
        } else if (groupId?.length) {
          setSelectedShareWithOption(SHARE_WITH_OPTIONS.group);
        }
      }

      if (videoUrl) {
        setVideoState({
          isSaved: true,
          isDone: true,
          videoUrl: videoUrl,
        });
      }
    }
  };

  const getFeedback = async (feedbackId) => {
    try {
      setIsLoadingData((prev) => ({ ...prev, feedback: true }));
      const { data } = await axios({
        url: `${url}/api/feedback/${feedbackId}${user.slug}`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      if (data?.result?.length) {
        const feedback = data.result[0];

        const {
          feedbackRequestId,
          feedbackType,
          title,
          userID,
          requestedDate,
          endDate,
          isStopAcceptingResponse,
          everyoneInOrganization,
          status,
          videoQuestionUrl,
          pollOptions,
          feedbackRequestUser,
          FeedbackSubmissions,
          isAutoLogoutAfterSubmission,
          description,
          ...rest
        } = feedback;

        let formattedFeedback = {
          ...rest,
          id: feedbackRequestId,
          title,
          dateCreated: requestedDate,
          feedbackEndsOn: endDate,
          feedbackType: feedbackType,
          status,
          pollOptions:
            Array.isArray(pollOptions) && pollOptions.length ? pollOptions : [],
          sharedWith: FeedbackSubmissions,
          videoUrl: videoQuestionUrl,
          stopAcceptingResponse: isStopAcceptingResponse,
          createdBy: feedbackRequestUser.email ?? "",
          isAutoLogoutAfterSubmission,
          description,
          isEveryoneInTheOrganization: everyoneInOrganization,
        };
        handleFeedbackData(formattedFeedback);
      } else {
        history.push("/feedback/text-feedback");

        // setFeedbackQuestion("");
        setSelectedDateTime(
          moment().add("minutes", 31).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        );
        // setSharedWith({
        //   isEveryoneInTheOrganization: false,
        //   classrooms: [],
        //   groups: [],
        // });
        // setOptions([
        //   { option: 1, value: "" },
        //   { option: 2, value: "" },
        //   { option: 3, value: "" },
        // ]);
        // setIsAutoLogOutAfterSubmission(false);
        // setDescription("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingData((prev) => ({ ...prev, feedback: false }));
    }
  };

  useEffect(() => {
    let feedbackId = query.get("feedback");
    if (feedbackId) {
      // if (location.state?.feedback && location.state.feedback?.id) {
      //   handleFeedbackData(location.state.feedback);
      // } else {
      getFeedback(feedbackId);
      // }
    } else {
      setFeedbackType(type);
      setFeedbackData(null);
      setFeedbackQuestion("");
      setSelectedDateTime(
        moment().add("minutes", 31).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      );
      setSharedWith({
        isEveryoneInTheOrganization: false,
        classrooms: [],
        groups: [],
      });
      setOptions([
        { option: 1, value: "" },
        { option: 2, value: "" },
        { option: 3, value: "" },
      ]);
      setIsAutoLogOutAfterSubmission(false);
      setDescription("");

      setQuestions([
        {
          id: `NEW_QUESTION_${1}`,
          questionType: type,
          questionTitle: "",
          isNew: true,
          pollOptions:
            type === FEEDBACK_TYPES.poll
              ? [
                  { option: 1, value: "" },
                  { option: 2, value: "" },
                  { option: 3, value: "" },
                ]
              : null,
        },
      ]);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    setIsMultiItemFeedback(
      [FEEDBACK_TYPES.text, FEEDBACK_TYPES.poll].includes(feedbackType) ||
        (FEEDBACK_TYPES.any === feedbackType &&
          feedbackData?.id &&
          feedbackData.isMixMode)
    );
  }, [isMixMode, feedbackType, feedbackData]);

  useEffect(() => {
    let isMixModeQuestions = questions
      .filter(({ isDeleted }) => !isDeleted)
      .some(({ questionType }) => questionType !== feedbackType);
    setIsMixMode(isMixModeQuestions);
  }, [questions, feedbackType]);

  const getConfirmFlagForVideo = (val) => {
    setConfirmVideoDelete(false);

    if (!val) {
      setVideoState({
        ...videoState,
        open: false,
      });
    } else {
      setVideoState({
        ...videoState,
        isStart: false,
        isSaved: false,
        isDone: false,
        open: false,
        videoUrl: "",
        isClosedWithoutSaved: false,
        isVideoRecorded: false,
      });
      NotificationAlert(feedbackTranslation.videoDeleted, "success");
    }
  };

  const typeOfFeedback = (type) => {
    switch (type) {
      case "TEXT":
        return feedbackTypesTranslation.text;
      case "AUDIO":
        return feedbackTypesTranslation.audio;
      case "VIDEO":
        return feedbackTypesTranslation.video;
      case "POLL":
        return feedbackTypesTranslation.poll;
      case "ANY":
        return feedbackTypesTranslation.any;
      default:
        return feedbackTypesTranslation.text;
    }
  };

  const validateFeedbackQuestions = () => {
    let isValid = true;

    let isMultiItem =
      [FEEDBACK_TYPES.text, FEEDBACK_TYPES.poll].includes(feedbackType) ||
      (FEEDBACK_TYPES.any === feedbackType &&
        feedbackData?.id &&
        feedbackData.isMixMode);

    if (isMultiItem) {
      let formattedQuestions = questions.filter(({ isDeleted }) => !isDeleted);
      if (!formattedQuestions.length) {
        NotificationAlert(
          feedbackTranslation.minimumQuestionValidation,
          "error"
        );
        isValid = false;
      } else {
        isValid = questions
          .filter(({ isDeleted }) => !isDeleted)
          .every(({ questionTitle, questionType, pollOptions }) => {
            if ([FEEDBACK_TYPES.poll].includes(questionType)) {
              return (
                !!questionTitle?.length &&
                pollOptions.every(({ value }) => !!value?.length)
              );
            } else {
              return !!questionTitle?.length;
            }
          });
      }
    }
    return isValid;
  };

  const feedbackValidator = () => {
    let isValid = true;

    const isSharedWithAll = !!sharedWith?.isEveryoneInTheOrganization;
    const isSharedWithParents = sharedWith?.isParentsInTheOrganization;

    const classIds = classroomsData?.length
      ? classroomsData.map((classroom) => classroom.id)
      : [];
    const groupIds = groupsData?.length
      ? groupsData.map((group) => group.id)
      : [];
    const sharedWithClassIds = sharedWith?.classrooms?.length
      ? sharedWith.classrooms
          .map((item) => item.id)
          .filter((classId) => classIds.includes(classId))
      : [];
    const sharedWithGroupIds = sharedWith?.groups?.length
      ? sharedWith.groups
          .map((item) => item.id)
          .filter((groupId) => groupIds.includes(groupId))
      : [];

    if (
      !isSharedWithAll &&
      !sharedWithClassIds.length &&
      !sharedWithGroupIds.length &&
      !isSharedWithParents
    ) {
      NotificationAlert(feedbackTranslation.shareWithValidation, "error");
      return false;
    }

    if (
      !feedbackQuestion.length ||
      !selectedDateTime ||
      !moment(selectedDateTime).isValid() ||
      // moment().isAfter(moment(selectedDateTime)) ||
      moment(selectedDateTime).isBefore(moment().add("minute", 30)) ||
      !validateFeedbackQuestions()
    ) {
      isValid = false;
    }
    return isValid;
  };

  const uploadFileToBlob = async (file) => {
    let storagePath = `feedback/newfeedback/${
      user.mail
    }-${Date.now()}-${feedbackQuestion?.replace(/\s/g, "")?.replace("/", "-")}`;
    const containerName = `feedback`;
    const storageAccountName = process.env.REACT_APP_STORAGE;

    const sasToken = process.env.REACT_APP_AZURE_SAS_TOKEN.replace(
      /"/g,
      ""
    );

    if (!file) return [];

    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/${sasToken}`
    );

    const containerClient = blobService.getContainerClient(containerName);

    const blobClient = containerClient.getBlockBlobClient(storagePath);

    const option = { blobHTTPHeaders: { blobContentType: file.type } };

    let response = await blobClient.uploadBrowserData(file, option);

    // getBlobsInContainer(containerClient);

    return response._response.request.url;
  };

  const createFeedbackHandler = async () => {
    setIsValidateFields(true);
    const isValid = feedbackValidator();
    let isSuccessFull = false;
    if (isValid) {
      setIsSubmitting(true);
      const formattedQuestionType = questions
        .filter(({ isDeleted }) => !isDeleted)
        .some(({ questionType }) => questionType !== feedbackType)
        ? FEEDBACK_TYPES.any
        : feedbackType;

      let isNotMultiItem =
        [FEEDBACK_TYPES.audio, FEEDBACK_TYPES.video].includes(feedbackType) ||
        (FEEDBACK_TYPES.any === feedbackType && !isMixMode);

      let formattedQuestions = isNotMultiItem
        ? questions?.length
          ? questions.map(({ id, ...rest }) => ({
              ...rest,
              questionType: feedbackType,
              questionTitle: feedbackQuestion,
              pollOptions: null,
              feedbackQuestionId: id,
            }))
          : []
        : questions?.length
        ? questions.map(({ id, ...rest }) => ({
            ...rest,
            feedbackQuestionId: id,
          }))
        : [];

      formattedQuestions = [...formattedQuestions].filter(
        ({ isNew, isDeleted }) => !(isNew && isDeleted)
      );

      try {
        const payload = {
          title: feedbackQuestion,
          endDate: moment(selectedDateTime).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          videoQuestionUrl: null,
          requestedBy: user.mail,
          feedbackType: formattedQuestionType,
          isStopAcceptingResponse: false,
          everyoneInOrganization: !!sharedWith.isEveryoneInTheOrganization,
          parentsInOrganization: sharedWith.isParentsInTheOrganization,
          status: "Progress",
          groupId: [],
          classId: [],
          pollOptions: feedbackType === FEEDBACK_TYPES.poll ? options : null,
          isAutoLogoutAfterSubmission: isAutoLogOutAfterSubmission,
          description,
          questions: formattedQuestions,
          isMixMode,
        };

        if (videoState.videoUrl) {
          payload.videoQuestionUrl = await uploadFileToBlob(
            await fetch(videoState.videoUrl).then((r) => r.blob())
          );
        }

        if (
          sharedWith.isEveryoneInTheOrganization ||
          sharedWith.isParentsInTheOrganization
        ) {
          payload.groupId = [];
          payload.classId = [];
        } else {
          const classIds = classroomsData?.length
            ? classroomsData.map((classroom) => classroom.id)
            : [];
          const groupIds = groupsData?.length
            ? groupsData.map((group) => group.id)
            : [];
          payload.classId = sharedWith.classrooms?.length
            ? sharedWith.classrooms
                .map((item) => item.id)
                .filter((classId) => classIds.includes(classId))
            : [];
          payload.groupId = sharedWith.groups?.length
            ? sharedWith.groups
                .map((item) => item.id)
                .filter((groupId) => groupIds.includes(groupId))
            : [];
        }

        if (feedbackData?.id) {
          const { data } = await axios({
            url: `${url}/api/feedback/${feedbackData.id}${user.slug}`,
            method: "POST",
            data: payload,
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          });
          if (data?.status?.toLowerCase() === "success") {
            toast.success(
              typeof data.result === "string"
                ? data.result
                : feedbackTranslation.feedbackUpdated
            );
            isSuccessFull = true;
          } else {
            toast.error(
              typeof data.message === "string"
                ? data.message
                : commonTranslation.somethingWentWrong
            );
          }
        } else {
          const { data } = await axios({
            url: `${url}/api/feedback${user.slug}`,
            method: "POST",
            data: payload,
            headers: {
              Authorization: `Bearer ${user.accessToken}`,
            },
          });

          if (data?.status?.toLowerCase() === "success") {
            toast.success(
              typeof data.result === "string"
                ? data.result
                : feedbackTranslation.feedbackCreated
            );
            isSuccessFull = true;
          } else if (data?.status?.toLowerCase() === "error") {
            if (data.showPopup) {
              setFeedbackLimitDialog({ show: true, message: data.message });
            }
          } else {
            if (data?.message) {
              toast.error(data?.message);
            } else {
              toast.error(
                typeof data.result === "string"
                  ? data.result
                  : feedbackTranslation.feedbackCreationError
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
        toast.error(
          error?.response?.data?.message ??
            error?.message ??
            "Something went wrong"
        );
      } finally {
        setIsSubmitting(false);
        setIsSubmitted(true);
        if (isSuccessFull) {
          setIsChangedForm(false);
          history.push("/feedback/all-feedback");
        }
      }
    }
  };

  const autoLogoutChangeHandler = (e, { checked }) => {
    setIsAutoLogOutAfterSubmission(checked);
  };

  useEffect(() => {
    if (!isSubmitted && isChangedForm && !isOpenConfirmDialog) {
      const unblock = history.block((tx) => {
        setNextLocation(tx);
        setShowDialog(true);
        setIsOpenConfirmDialog(true);
        return false;
      });

      return () => {
        unblock();
      };
    }
  }, [isChangedForm, history, isOpenConfirmDialog, isSubmitted]);

  const handleConfirm = () => {
    setShowDialog(false);
    if (nextLocation) {
      setIsChangedForm(false); // Reset the changed form state
      history.push(nextLocation.pathname);
    }
  };

  const handleCancel = () => {
    setShowDialog(false);
    setNextLocation(null);
    setIsOpenConfirmDialog(false);
  };
  return (
    <div className="text-feedback-container">
      <Dialog
        cancelButton="Cancel"
        confirmButton="Confirm"
        content={feedbackTranslation.unSavedChangesConfirmation}
        header={t("alertDialog").topMessage || "Are you sure ?==="}
        open={showDialog}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        styles={{ maxWidth: "600px" }}
      />
      <Prompt
        when={isChangedForm}
        message={feedbackTranslation.unSavedChangesConfirmation}
      />
      <div className="text-feedback">
        <Dialog
          open={confirmVideoDelete}
          onConfirm={() => getConfirmFlagForVideo(true)}
          onCancel={() => getConfirmFlagForVideo(false)}
          header={feedbackTranslation.deleteVideo}
          content={
            <Flex column>
              <Text>{feedbackTranslation.videoDeleteConfirmation}</Text>
            </Flex>
          }
          confirmButton={commonTranslation.yes}
          cancelButton={commonTranslation.no}
          headerAction={{
            icon: <CloseIcon />,
            title: commonTranslation.close,
            onClick: () => {
              setConfirmVideoDelete(false);
            },
          }}
          styles={{ maxWidth: "600px" }}
        />

        {isLoadingData.feedback ? (
          <Loader />
        ) : (
          <div className="ms-Grid">
            <Flex vAlign="center">
              <IconButton
                iconProps={{ iconName: "ChromeBack" }}
                title={commonTranslation.back}
                ariaLabel={commonTranslation.back}
                onClick={() => {
                  history.push({
                    pathname: "/feedback/all-feedback",
                  });
                }}
              />
              <Text
                color="brand"
                size="large"
                weight="semibold"
                style={{ marginRight: "10px" }}
              >
                {feedbackData?.id
                  ? feedbackTranslation.edit
                  : feedbackTranslation.new}{" "}
                {typeOfFeedback(feedbackType)} {feedbackTranslation.feedback}
              </Text>
            </Flex>
            <div className="ms-Grid-row feedback-form-body-section">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                <Flex column>
                  <FeedbackQuestions
                    {...{
                      ...props,
                      feedbackQuestion,
                      setFeedbackQuestion,
                      isValidateFields,
                      description,
                      setDescription,
                      feedbackType,
                      SHARE_WITH_OPTIONS,
                      FEEDBACK_TYPES,
                      sharedWith,
                      setSharedWith,
                      isLoadingData:
                        isLoadingData?.groupClass || isLoadingData?.feedback,
                      selectedShareWithOption,
                      setSelectedShareWithOption,
                      groupsData,
                      classroomsData,
                      selectedDateTime,
                      setSelectedDateTime,
                      questions,
                      setQuestions,
                      isMixMode,
                      setIsMixMode,
                      initialType: feedbackType,
                      typeOfFeedback,
                      isMultiItemFeedback,
                      setIsMultiItemFeedback,
                      feedbackData,
                      setIsChangedForm,
                    }}
                  />
                </Flex>
              </div>
              {feedbackType !== FEEDBACK_TYPES.poll && (
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                  <VideoQuestion
                    videoState={videoState}
                    setVideoState={setVideoState}
                    setConfirmVideoDelete={setConfirmVideoDelete}
                    setIsChangedForm={setIsChangedForm}
                  />
                </div>
              )}
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                <Flex
                  vAlign="center"
                  space="between"
                  wrap
                  className="feedback-form-footer-wrapper"
                >
                  <Flex>
                    <Checkbox
                      styles={{ alignItems: "center" }}
                      label={feedbackTranslation.autoLogout}
                      toggle
                      checked={isAutoLogOutAfterSubmission}
                      onChange={autoLogoutChangeHandler}
                      disabled={isSubmitting}
                    />
                  </Flex>
                  <Flex gap="gap.medium" className="action-btn-wrapper">
                    <Button
                      onClick={() => {
                        history.push({
                          pathname: "/feedback/all-feedback",
                        });
                      }}
                      disabled={isSubmitting}
                    >
                      {commonTranslation.cancel}
                    </Button>
                    <Button
                      primary
                      loading={isSubmitting}
                      onClick={() => {
                        createFeedbackHandler();
                      }}
                      disabled={isSubmitting}
                      content={
                        isSubmitting
                          ? commonTranslation.loading
                          : feedbackData?.id
                          ? commonTranslation.save
                          : commonTranslation.publish
                      }
                    />
                  </Flex>
                </Flex>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(FeedbackForm);
