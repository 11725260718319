import React from "react";
import {
  Button,
  Card,
  CardBody,
  CloseIcon,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import VideoRecorder from "react-video-recorder";
import { FileUploader } from "react-drag-drop-files";

import FeedbackDialog from "../../shared/FeedbackDialog";
import { NotificationAlert } from "../../../../../components/Notification/Notification";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

const VideoQuestion = (props) => {
  const {
    videoState,
    setVideoState,
    setConfirmVideoDelete,
    setIsChangedForm,
    t,
  } = props;

  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const feedbackTranslation = translation.body.feedbackForm;
  const feedbackTypesTranslation = translation.body.feedbackTypes;

  const handleVideoRecordConfirm = () => {
    if (videoState.isDone) {
      setConfirmVideoDelete(true);
    } else if (!videoState.isSaved) {
      setVideoState({
        ...videoState,
        isSaved: true,
        isClosedWithoutSaved: false,
      });
    } else if (videoState.videoUrl) {
      setVideoState({
        ...videoState,
        isDone: true,
        open: false,
        isClosedWithoutSaved: false,
        isVideoRecorded: false,
      });
      NotificationAlert(feedbackTranslation.videoAdded, "success");
    } else {
      setVideoState({
        ...videoState,
        isDone: false,
        isSaved: false,
        open: false,
      });
    }
    setIsChangedForm(true);
  };

  const VideoContent = () => {
    return (
      <div className="video-question-wrapper">
        {(videoState.isVideoRecorded ||
          videoState.isDone ||
          videoState.isSaved) &&
          videoState.videoUrl ? (
          <div className="justify-center">
            <video controls src={videoState.videoUrl} />
          </div>
        ) : (
          <div>
            <Flex className="vider-recorder-wrapper">
              <VideoRecorder
                onRecordingComplete={(videoBlob) => {
                  if ({ ...videoState }.isClosedWithoutSaved) {
                    setVideoState((prevState) => ({
                      ...prevState,
                      isVideoRecorded: true,
                      isDone: false,
                      isSaved: false,
                      videoUrl: "",
                      isClosedWithoutSaved: false,
                    }));
                  } else {
                    setVideoState((prevState) => ({
                      ...prevState,
                      videoUrl: videoBlob ? URL.createObjectURL(videoBlob) : "",
                      isVideoRecorded: true,
                    }));
                  }
                  setIsChangedForm(true);
                }}
                isFlipped={false}
                className="videonote-box"
                renderDisconnectedView={() => <div></div>}
                replayVideoAutoplayAndLoopOff
                timeLimit={300000}
              />
            </Flex>
          </div>
        )}
      </div>
    );
  };

  return (
    <Flex style={{ marginTop: "15px" }}>
      <Flex column>
        <Flex>
          <Text style={{ fontSize: "14px" }} weight="semibold">
            {feedbackTranslation.askQuestionHeader}
          </Text>
        </Flex>
        <Flex>
          {videoState.isSaved && videoState.videoUrl ? (
            //  &&videoState.fromFileUploader
            <Flex style={{ marginTop: "10px" }}>
              <Flex
                column
                // style={{ maxWidth: "400px", maxHeight: "300px" }}
                style={{
                  maxWidth: "350px",

                  maxHeight: "250px",
                }}
              >
                <video
                  src={videoState.videoUrl}
                  controls
                  style={{
                    // maxWidth: "350px",
                    borderRadius: "12px",
                    // maxHeight: "250px",
                    width: "100%",
                  }}
                />
                <Flex style={{ margin: "10px 0 10px 10px" }}>
                  <Button
                    primary
                    onClick={() => {
                      // setVideoState({
                      //   ...videoState,
                      //   videoUrl: "",
                      //   isSaved: false,
                      // });
                      setConfirmVideoDelete(true);
                    }}
                  >
                    {feedbackTranslation.deleteVideo}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <Flex vAlign="center" wrap>
              <Flex style={{ margin: "5px" }}>
                <Card
                  elevated
                  className="file-browse-section-card"
                  fluid
                  styles={{
                    ":hover": { backgroundColor: "white" },
                    backgroundColor: "white",
                    width: "275px",
                    maxHeight: "170px",
                    borderRadius: "4px",
                    padding: "3px",
                  }}
                >
                  <CardBody
                    style={{
                      marginBottom: "0px",
                      marginTop: "0px",
                      height: "100%",
                    }}
                  >
                    <FileUploader

                      handleChange={(file) => {
                        console.log("file", file);
                        let blobUrl = URL.createObjectURL(file);
                        setVideoState({
                          ...videoState,
                          videoUrl: blobUrl,
                          isSaved: true,
                          fromFileUploader: true,
                        });
                        setIsChangedForm(true);
                      }}
                      types={["webm", "mp4", "mkv", "3gp", "mov"]}
                      className="file-browse-section-outer-main"
                      name="file"
                      fluid
                      onTypeError={(err) => {
                        toast.error(err)
                      }}
                    >
                      <Flex
                        hAlign="center"
                        style={{ border: " 1px dashed gray" }}
                      >
                        <div style={{ padding: "65px 0" }}>
                          <Flex column vAlign="center">
                            <Text align="center" size="small" weight="bold">
                              {feedbackTranslation.uploadVideo + " :"}

                            </Text>
                            <Text align="center" size="small">
                              WEBM, MP4, MKV, 3GP, MOV.
                            </Text>
                          </Flex>
                        </div>
                      </Flex>
                    </FileUploader>
                  </CardBody>
                </Card>
              </Flex>
              <Flex>
                <Text style={{ fontSize: "12px", margin: "5px" }} weight="bold">
                  {feedbackTranslation.or}
                </Text>
              </Flex>
              <Flex
                style={{
                  margin: "5px",
                }}
              >
                <Card
                  elevated
                  className="file-browse-section-card"
                  fluid
                  styles={{
                    ":hover": { backgroundColor: "white" },
                    backgroundColor: "white",
                    width: "275px",
                    // width: "100%",
                    maxHeight: "170px",
                    borderRadius: "4px",
                    padding: "3px",
                  }}
                >
                  <CardBody
                    style={{
                      marginBottom: "0px",
                      marginTop: "0px",
                      height: "100%",
                    }}
                  >
                    <Flex hAlign="center" style={{ border: "1px dashed gray" }}>
                      <div style={{ padding: "61px 0" }}>
                        <Flex column vAlign="center" hAlign="center">
                          <Text align="center" size="small" weight="bold">
                            {feedbackTranslation.recordVideo}
                          </Text>

                          <FeedbackDialog
                            closeOnOutsideClick={false}
                            button={
                              <Button size="small">
                                {feedbackTranslation.recordNow}
                              </Button>
                            }
                            confirmButton={
                              videoState.isDone
                                ? feedbackTranslation.deleteThisVideo
                                : commonTranslation.done
                            }
                            cancelButton={"Retake video"}
                            header={feedbackTranslation.recordVideo}
                            onConfirm={handleVideoRecordConfirm}
                            onOpen={() => { }}
                            onCancel={() => {
                              if (videoState.isVideoRecorded) {
                                setVideoState({
                                  ...videoState,
                                  videoUrl: null,
                                  isVideoRecorded: false
                                });
                                setIsChangedForm(true);
                              }
                            }}
                            content={{
                              content: <VideoContent />,
                              styles: {
                                height: "100%",
                              },
                            }}
                            headerAction={{
                              icon: <CloseIcon />,
                              title: commonTranslation.close,
                              onClick: () => {
                                setVideoState({
                                  ...videoState,
                                  open: false,
                                  // isStart: false,
                                  // isStop: false,
                                  // isDone: false,
                                });
                                setIsChangedForm(true);
                              },
                            }}
                          />
                        </Flex>
                      </div>
                    </Flex>
                  </CardBody>
                </Card>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default withTranslation()(VideoQuestion);
