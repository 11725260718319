import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  AttendeeIcon,
  CalendarIcon,
  CheckmarkCircleIcon,
  CollapseIcon,
  ContactGroupIcon,
  ContentIcon,
  ExpandIcon,
  MenuIcon,
  PollIcon,
  SettingsIcon,
  ShareAltIcon,
  WhiteboardIcon,
} from "@fluentui/react-icons-northstar";
import {
  Avatar,
  Button,
  Card,
  CloseIcon,
  Dialog,
  Divider,
  Flex,
  Image,
  Input,
  Layout,
  MenuButton,
  Pill,
  // Alert,
  RedoIcon,
  SearchIcon,
  Segment,
  Text,
  TextArea,
  UserFriendsIcon,
} from "@fluentui/react-northstar";
import Logo from "../../Assets/images/logo.png";
import LogoLight from "../../Assets/images/logo.png";

import * as Icon from "react-feather";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import i18n from "../../helper/i18n";

import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Header.css";
import { ShareButtons } from "../ShareButtons/ShareButtons";

const url = process.env.REACT_APP_EP_URL;

const Header = (props) => {
  var elem = document.documentElement;
  const [isFullscreen, setFullscreen] = React.useState(false);
  const [ham, setHam] = React.useState(false);
  const [help, setHelp] = React.useState(false);
  const [msgSend, setMsgSend] = React.useState(null);
  const [notification, setNotification] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [referModal, setReferModal] = React.useState(false);
  const [referralCode, setReferralCode] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [openIsModal, setIsOpenModal] = React.useState(false);
  const [pathname, setPathName] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [selLan, setSelLan] = React.useState(i18n.language);
  const [UrlPhoto, setUrlPhoto] = React.useState(null);
  const [isMobileView, setIsMobileView] = React.useState(
    window.innerWidth <= 767
  );
  const [isTabletView, setIsTabletView] = React.useState(
    window.innerWidth <= 991
  );
  const [emailInputText, setEmailInputText] = React.useState("");
  const [isInvalidEmailText, setIsInvalidEmailText] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const [loading, setLoading] = React.useState({
    type: null,
    isLoading: false,
  });
  const [isEmailsExpanded, setIsEmailsExpanded] = React.useState(false);
  const [isWBFullScreen, setIsWBFullScreen] = React.useState(false);

  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const emailLoading = "emailLoading";
  const translation = t("feedbackModule");

  const commonTranslation = translation.common;
  const shareFeedbackTranslation = translation.body.allFeedback.shareFeedback;

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
      setIsTabletView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const screen = window.localStorage.getItem("screen");
    // fetch user referral code
    getToken();

    if (
      screen &&
      (location.pathname?.includes("report-by") ||
        ["/attendance-special-report", "/feedback-report"].includes(
          location.pathname
        ))
    ) {
      document
        .querySelector(".dashboard-menu-section ")
        ?.classList?.remove("c-none");
      document
        .querySelector(".dashboard-menu-section ")
        ?.setAttribute("data-content", screen);
    } else {
      document
        .querySelector(".dashboard-menu-section ")
        ?.classList?.add("c-none");
    }
    setOptionSelection();
    getCurrentIndex();

    window.addEventListener("message", (e) => {
      if (e?.data?.type === "CONFIRMATION_MODAL") {
        setPopup(e.data.isShowInput);
      }
    });
  }, []);

  useEffect(() => {
    const msg = JSON.parse(localStorage.getItem("msg"));
    localStorage.setItem("msg", JSON.stringify({ ...msg, i18nextLng: selLan }));
  }, [selLan]);

  useEffect(() => {
    setFullscreen(!!document.fullscreenElement);
    document.addEventListener("fullscreenchange", () => {
      setFullscreen(!!document.fullscreenElement);
    });

    window.addEventListener("message", (e) => {
      if (e?.data?.type === "ZOOM_IN_OUT") {
        setIsWBFullScreen(e.data.isZoom);
      }
    });
  }, []);

  useEffect(() => {
    setUrlPhoto(
      props.user.UrlPhoto
        ? props.user.UrlPhoto + `&date=${new Date().getSeconds()}`
        : null
    );
  }, [props.user]);

  const getCurrentIndex = () => {
    if (props.path === "/attendance-report-by-subject") {
      setCurrentIndex(0);
      return;
    }
    if (props.path === "/attendance-report-by-student") {
      setCurrentIndex(1);
      return;
    }
    if (props.path === "/attendance-report-by-teacher") {
      setCurrentIndex(2);
      return;
    }
    if (props.path === "/attendance-special-report") {
      setCurrentIndex(3);
      return;
    }
    setCurrentIndex(-1);
  };

  const onAddEmailHandler = () => {
    let emailInputList = emailInputText?.length
      ? [
          ...new Set(
            emailInputText
              .toLowerCase()
              .split(";")
              .filter((email) => email)
          ),
        ]
      : [];
    if (!emailInputList.length) {
      return;
    }
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    let isValid = true;
    emailInputList.forEach((emailText) => {
      if (!emailRegex.test(emailText)) {
        isValid = false;
      }
    });

    if (emailInputText?.includes(",")) {
      isValid = false;
    }

    if (isValid) {
      setEmails((prev) => [...new Set([...prev, ...emailInputList])]);
      setEmailInputText("");
    } else {
      setIsInvalidEmailText(true);
    }
  };

  const sendEmailInvitationHandler = async () => {
    try {
      setLoading({ type: emailLoading, isLoading: true });

      const payload = {
        emails,
        link: `https://www.acv.app/prices/${referralCode}`,
        schoolInfo: props?.user?.schoolInfo,
        language: selLan,
        referBy: props?.user?.name,
      };

      const { data } = await axios({
        method: "POST",
        url: `${url}/api/refer/shareReferLink`,
        data: payload,
        headers: {
          Authorization: `Bearer ${props.user.accessToken}`,
        },
      });
      if (data) {
        if (
          data.status?.toLowerCase() === "success" &&
          typeof data.message === "string"
        ) {
          toast.success(data.message);
          setEmails([]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading({ type: null, isLoading: false });
    }
  };

  const setOptionSelection = () => {
    const isActive =
      props.path === "/attendance-report-by-subject" ||
      props.path === "/attendance-report-by-student" ||
      props.path === "/attendance-report-by-teacher" ||
      props.path === "/attendance-special-report";
    const button = `<span dir="auto"  class="button dashboard-btn ${
      isActive && "activeBtn activeTab"
    }">Dashboard</span>`;
    const block = document.querySelector(
      ".DashNav .ui-dropdown__trigger-button"
    );
    if (block) {
      block.innerHTML = button;
    }
  };

  const toRedirect = (path) => history.push(path);

  window.addEventListener("load", () => {
    document
      .getElementsByClassName("docs-btn")[0]
      ?.parentNode?.parentNode?.parentNode?.classList.add("docs-list");
  });

  const copyTextClipboard = () => {
    const link = `https://www.acv.app/prices/${referralCode}`;
    toast.success("Referral Link copied successfully!");
    navigator.clipboard.writeText(link).then(
      function () {},
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  /* View in fullscreen! */
  function openFullscreen() {
    try {
      if (!document.fullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      }
      setFullscreen(true);
    } catch (err) {
      console.log(err);
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    try {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      }
      setFullscreen(false);
    } catch (err) {
      console.log(err);
    }
  }

  // useEffect(() => {
  //   if (isWBFullScreen) {
  //     openFullscreen();
  //   } else {
  //     closeFullscreen();
  //   }
  // }, [isWBFullScreen]);

  const openModal = () => {
    if (props.path === "attendance") {
      if (isMobileView || isTabletView) {
        props.handleClickOpen();
      } else {
        if (document.querySelector(".h-ctr")) {
          document.querySelector(".h-ctr").classList.add("s-ctr");
          document.querySelector(".h-ctr").classList.remove("h-ctr");
        } else if (document.querySelector(".s-ctr")) {
          document.querySelector(".s-ctr").classList.add("h-ctr");
          document.querySelector(".s-ctr").classList.remove("s-ctr");
        }
      }
    } else if (props.path === "whiteboard") {
      if (isMobileView || isTabletView) {
        props.handleClickOpen();
      } else {
        if (document.querySelector(".h-ctr")) {
          document.querySelector(".h-ctr").classList.add("s-ctr");
          document.querySelector(".h-ctr").classList.remove("h-ctr");
        } else if (document.querySelector(".s-ctr")) {
          document.querySelector(".s-ctr").classList.add("h-ctr");
          document.querySelector(".s-ctr").classList.remove("s-ctr");
        }
      }
    } else if (props.path === "/dashboard") {
      if (isMobileView || isTabletView) {
        props.openDashboardMenu();
      } else {
        console.log("called------------");
        if (document.querySelector(".h-ctr")) {
          document.querySelector(".h-ctr").classList.add("s-ctr");
          document.querySelector(".h-ctr").classList.remove("h-ctr");
        } else if (document.querySelector(".s-ctr")) {
          document.querySelector(".s-ctr").classList.add("h-ctr");
          document.querySelector(".s-ctr").classList.remove("s-ctr");
        }
      }
    } else if (props.path === "history") {
      if (isMobileView || isTabletView) {
        props.openFilterDialog();
      } else {
        if (document.querySelector(".h-ctr")) {
          document.querySelector(".h-ctr").classList.add("s-ctr");
          document.querySelector(".h-ctr").classList.remove("h-ctr");
        } else if (document.querySelector(".s-ctr")) {
          document.querySelector(".s-ctr").classList.add("h-ctr");
          document.querySelector(".s-ctr").classList.remove("s-ctr");
        }
      }
    } else if (props.path === "events" || props.path === "feedback") {
      if (document.querySelector(".h-ctr")) {
        document.querySelector(".h-ctr").classList.add("s-ctr");
        document.querySelector(".h-ctr").classList.remove("h-ctr");
      } else if (document.querySelector(".s-ctr")) {
        document.querySelector(".s-ctr").classList.add("h-ctr");
        document.querySelector(".s-ctr").classList.remove("s-ctr");
      }
    } else if (props.path === "assignments") {
      props.toggleControl();
    }
  };

  const multilngbtn = {
    content: (
      <Button
        icon={<Icon.Globe />}
        content="Language"
        aria-label="Hover button"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
        onClick={() => {
          setIsOpen(true);
        }}
      />
    ),
    menu: {
      items: [
        {
          content: (
            <Button
              // icon={<Flags.US title="English" />}
              content="English"
              className={selLan != "en" ? "FlagBtn" : "FlagBtn selLan"}
              iconOnly
            />
          ),
          key: "English",
          onClick: () => {
            popup
              ? setPathName("english")
              : (setSelLan("en"), i18n.changeLanguage("en"), setIsOpen(false));
            popup ? setIsOpenModal(true) : setIsOpenModal(false);
          },
        },
        {
          content: (
            <Button
              // icon={<Flags.MX title="Spanish" />}
              content="Español"
              className={selLan != "es" ? "FlagBtn" : "FlagBtn selLan"}
              iconOnly
            />
          ),
          key: "Español",
          onClick: () => {
            popup
              ? setPathName("Español")
              : (setSelLan("es"), i18n.changeLanguage("es"), setIsOpen(false));
            popup ? setIsOpenModal(true) : setIsOpenModal(false);
          },
        },
        {
          content: (
            <Button
              // icon={<Flags.BR title="Portuguese" />}
              content="Português"
              className={selLan != "pt" ? "FlagBtn" : "FlagBtn selLan"}
              iconOnly
            />
          ),
          key: "Português",
          onClick: () => {
            popup
              ? setPathName("Português")
              : (setSelLan("pt"), i18n.changeLanguage("pt"), setIsOpen(false));
            popup ? setIsOpenModal(true) : setIsOpenModal(false);
          },
        },
        {
          content: (
            <Button
              // icon={<Flags.FR title="French" />}
              content="Français"
              className={selLan != "fr" ? "FlagBtn" : "FlagBtn selLan"}
              iconOnly
            />
          ),
          key: "Français",
          onClick: () => {
            popup
              ? setPathName("Français")
              : (setSelLan("fr"), i18n.changeLanguage("fr"), setIsOpen(false));
            popup ? setIsOpenModal(true) : setIsOpenModal(false);
          },
        },
        {
          content: (
            <Button
              // icon={<Flags.DE title="Deutsch" />}
              content="Deutsch"
              className={selLan != "de" ? "FlagBtn" : "FlagBtn selLan"}
              iconOnly
            />
          ),
          key: "Deutsch",
          onClick: () => {
            popup
              ? setPathName("Deutsch")
              : (setSelLan("de"), i18n.changeLanguage("de"), setIsOpen(false));
            popup ? setIsOpenModal(true) : setIsOpenModal(false);
          },
        },
        {
          content: (
            <Button
              // icon={<Flags.IN title="Hindi" />}
              content="हिंदी"
              className={selLan != "hi" ? "FlagBtn" : "FlagBtn selLan"}
              iconOnly
            />
          ),
          key: "हिंदी",
          onClick: () => {
            popup
              ? setPathName("हिंदी")
              : (setSelLan("hi"), i18n.changeLanguage("hi"), setIsOpen(false));
            popup ? setIsOpenModal(true) : setIsOpenModal(false);
          },
        },
      ],
    },
    key: "Multi-Language",
  };

  const switchDB = {
    content: (
      <Button
        icon={<Icon.Repeat />}
        content="Switch Org."
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
      />
    ),
    key: "switch-org-btn",
    onClick: () => {
      props.isEditProfile === true ||
      location.pathname === "/assignments/create" ||
      location.pathname === "/assignments/assignmnet/rubric" ||
      location.pathname === "/feedback/text-feedback" ||
      location.pathname === "/event/new" ||
      props.isNew === true ||
      props.addCategory?.isEdit === true ||
      props.addTag?.isEdit === true ||
      props.addSkill?.isEdit === true ||
      props.addInterest?.isEdit === true ||
      popup
        ? setPathName("switchDB")
        : props.switchDb(),
        // history.push("/switch-db");

        props.isEditProfile === true ||
        location.pathname === "/assignments/create" ||
        location.pathname === "/assignments/assignmnet/rubric" ||
        location.pathname === "/feedback/text-feedback" ||
        location.pathname === "/event/new" ||
        props.isNew === true ||
        props.addCategory?.isEdit === true ||
        props.addTag?.isEdit === true ||
        props.addSkill?.isEdit === true ||
        props.addInterest?.isEdit === true ||
        popup
          ? setIsOpenModal(true)
          : setIsOpenModal(false);

      // props.switchDb();
      // history.push("/switch-db");
    },
  };

  const rollBackDB = {
    content: (
      <Button
        icon={<RedoIcon outline size="large" />}
        content="Switch Data"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
      />
    ),
    key: "rollBackDB-btn",
    onClick: () => {
      setOpen(true);
    },
  };

  const docsbtn = {
    content: (
      <Button icon={<Icon.BookOpen />} content="Docs" className="docs-btn" />
    ),
    key: "Docs-btn",
    onClick: () => {
      window.open("https://docs.acv.app", "_blank");
      // history.push("/docs");
    },
  };

  const helpbtn = {
    content: (
      <Button
        icon={<Icon.HelpCircle />}
        content="Help"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
      />
    ),
    key: "Help-btn",
    onClick: () => {
      setMsgSend(null);
      setHelp(true);
    },
  };

  const referbtn = {
    content: (
      <Button
        icon={<Icon.Share2 />}
        content="Refer"
        onClick={setReferModal}
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
      />
    ),
    key: "Refer-btn",
    onClick: () => {
      setReferModal(true);
    },
  };

  const accountbtn = {
    content: (
      <Button
        icon={<Icon.UserCheck />}
        content="Account"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
      />
    ),
    key: "Account-btn",
    onClick: () => {
      props.isEditProfile === true ||
      location.pathname === "/assignments/create" ||
      location.pathname === "/assignments/assignmnet/rubric" ||
      location.pathname === "/feedback/text-feedback" ||
      location.pathname === "/event/new" ||
      props.isNew === true ||
      props.addCategory?.isEdit === true ||
      props.addTag?.isEdit === true ||
      props.addSkill?.isEdit === true ||
      props.addInterest?.isEdit === true ||
      popup
        ? setPathName("accountbtn")
        : history.push("/account");

      props.isEditProfile === true ||
      location.pathname === "/assignments/create" ||
      location.pathname === "/assignments/assignmnet/rubric" ||
      location.pathname === "/feedback/text-feedback" ||
      location.pathname === "/event/new" ||
      props.isNew === true ||
      props.addCategory?.isEdit === true ||
      props.addTag?.isEdit === true ||
      props.addSkill?.isEdit === true ||
      props.addInterest?.isEdit === true ||
      popup
        ? setIsOpenModal(true)
        : setIsOpenModal(false);
    },
  };

  const logoutbtn = {
    content: (
      <Button
        icon={<Icon.LogOut />}
        content="Logout"
        style={{
          margin: "0",
          padding: "0",
          boxShadow: "none",
          backgroundColor: "transparent",
          border: "none",
          justifyContent: "start",
        }}
      />
    ),
    key: "Logout-btn",
    onClick: () => props.logout(),
  };

  const getToken = () => {
    const headers = {
      Authorization: "Bearer " + props.user.accessToken,
    };
    const email = props?.user?.mail;
    const API = "/api/refer/getReferToken";

    // const https://www.acv.app/prices/Bqb0ycKN = localStorage.getItem("REFER-CODE");
    // console.log("email", email, referCode);
    // if (!referCode) {
    axios
      .post(
        `${url}${API}`,
        {
          email: email || "pd@devtim.tech",
        },
        { headers }
      )
      .then((res) => {
        // localStorage.setItem("REFER-CODE", res?.data?.referCode);
        setReferralCode(res?.data?.referCode);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
        setMsgSend({
          status: "Error",
          msg: "Refer token couldn't be get. Try to contact Tangible IT Admins.",
        });
      });
    // } else {
    //   setReferralCode(referCode);
    // }
  };
  // <Card aria-roledescription="card avatar">
  const profileButton = (
    <MenuButton
      trigger={
        <Card.Header fitted>
          <Flex gap="gap.small">
            {/* ? props.user?.UrlPhoto + props.user?.SASToken */}
            <Avatar
              image={UrlPhoto ? UrlPhoto : null}
              // label="Copy bandwidth"
              name={
                props.user?.displayName
                  ? props.user?.displayName
                  : props.user?.name.toUpperCase()
              }
              status={{
                color:
                  props?.user?.actualRole === "SuperAdmin"
                    ? "#f25767"
                    : props?.user?.actualRole === "Admin"
                    ? "#ffaa44"
                    : props?.user?.actualRole === "Teacher"
                    ? "#6264a7"
                    : props?.user?.actualRole === "Student"
                    ? "#096dd9"
                    : "",
                // icon: <ContentIcon />,
                title: props?.user?.actualRole,
              }}
            />
            {window.innerWidth > 890 && (
              <Flex column>
                <Text
                  content={
                    props.user?.displayName
                      ? props.user?.displayName
                      : props.user?.name
                  }
                  weight="bold"
                  style={{
                    textOverflow: "ellipsis",
                    width: "90px",
                    maxWidth: "100%",
                    overflow: "hidden",
                    textWrap: "nowrap",
                  }}
                />
                <Text content={props.user?.role} size="small" />
              </Flex>
            )}
          </Flex>
        </Card.Header>
      }
      menu={
        props.user && props.user?.actualRole === "SuperAdmin"
          ? props.user?.actualRole === "SuperAdmin" &&
            props.user?.role === "Admin"
            ? [
                multilngbtn,
                switchDB,
                rollBackDB,
                docsbtn,
                helpbtn,
                referbtn,
                accountbtn,
                logoutbtn,
              ]
            : [
                multilngbtn,
                switchDB,
                {
                  content: (
                    <Button
                      icon={<Icon.UploadCloud />}
                      content="Startup"
                      style={{
                        margin: "0",
                        padding: "0",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        border: "none",
                        justifyContent: "start",
                      }}
                    />
                  ),
                  key: "Startup-btn",
                  onClick: () => {
                    props.isEditProfile === true ||
                    location.pathname === "/assignments/create" ||
                    location.pathname === "/assignments/assignmnet/rubric" ||
                    location.pathname === "/feedback/text-feedback" ||
                    location.pathname === "/event/new" ||
                    props.isNew === true ||
                    props.addCategory?.isEdit === true ||
                    props.addTag?.isEdit === true ||
                    props.addSkill?.isEdit === true ||
                    props.addInterest?.isEdit === true ||
                    popup
                      ? setPathName("Startup")
                      : history.push("/start-up");

                    props.isEditProfile === true ||
                    location.pathname === "/assignments/create" ||
                    location.pathname === "/assignments/assignmnet/rubric" ||
                    location.pathname === "/feedback/text-feedback" ||
                    location.pathname === "/event/new" ||
                    props.isNew === true ||
                    props.addCategory?.isEdit === true ||
                    props.addTag?.isEdit === true ||
                    props.addSkill?.isEdit === true ||
                    props.addInterest?.isEdit === true ||
                    popup
                      ? setIsOpenModal(true)
                      : setIsOpenModal(false);

                    // history.push("/start-up");
                  },
                },
                docsbtn,
                helpbtn,
                referbtn,
                {
                  content: (
                    <Button
                      icon={<Icon.User />}
                      content="Profile"
                      style={{
                        margin: "0",
                        padding: "0",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                        border: "none",
                        justifyContent: "start",
                      }}
                    />
                  ),
                  key: "Download-Invoice-btn",
                  onClick: () => {
                    window.open("https://www.acv.app/profile", "_blank");
                  },
                },
                accountbtn,
                logoutbtn,
              ]
          : props.user?.role.toLowerCase() === "parent"
          ? [multilngbtn, switchDB, docsbtn, helpbtn, accountbtn, logoutbtn]
          : props.user?.role.toLowerCase() === "externaltutor"
          ? [multilngbtn, helpbtn, accountbtn, logoutbtn]
          : [
              multilngbtn,
              switchDB,
              docsbtn,
              helpbtn,
              referbtn,
              accountbtn,
              logoutbtn,
            ]
      }
      mouseLeaveDelay={0}
      on={document.documentElement.clientWidth < 1060 ? "click" : "hover"}
      className="Avatar"
      key="menuBtn"
      open={isOpen}
      onOpenChange={(e, { open }) => setIsOpen(open)}
    />
  );

  const onDashboardOptionSelection = ({ path, tag }) => {
    toRedirect(`/${path}`);
    window.localStorage.setItem("screen", tag);
  };

  const getSelectedMenuClassName = ({ menuPath }) => {
    let currentPath = props.path;
    if (Array.isArray(menuPath)) {
      return menuPath.some((path) => currentPath === `/${path}`)
        ? "selected"
        : "";
    } else {
      return currentPath === `/${menuPath}` ? "selected" : "";
    }
  };

  const dashboardDropdownItems = [
    {
      key: "Dashboard",
      content: "Dashboard",
      className: `${
        ["/special-report", "/feedback-report"].includes(props.path) ||
        props.path?.includes("report-by")
          ? "selected"
          : ""
      }`,
      menu: {
        items: [
          {
            key: "attendance",
            content: "Attendance",
            on: "hover",
            className: getSelectedMenuClassName({
              menuPath: [
                "special-report",
                "report-by-subject",
                "report-by-teacher",
                "report-by-student",
              ],
            }),
            menu: [
              {
                key: "attendance1",
                content: "Report by subject",
                className: getSelectedMenuClassName({
                  menuPath: "report-by-subject",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "report-by-subject",
                    tag: "Subject",
                  });
                },
              },

              {
                key: "attendance2",
                content: "Report by student",
                className: getSelectedMenuClassName({
                  menuPath: "report-by-student",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "report-by-student",
                    tag: "Student",
                  });
                },
              },
              {
                key: "attendance3",
                content: "Report by teacher",
                className: getSelectedMenuClassName({
                  menuPath: "report-by-teacher",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "report-by-teacher",
                    tag: "Teacher",
                  });
                },
              },
              {
                key: "attendance4",
                content: "Special report",
                className: getSelectedMenuClassName({
                  menuPath: "special-report",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "special-report",
                    tag: "Special",
                  });
                },
              },
            ],
          },
          {
            key: "assignment",
            content: "Assignment",
            on: "hover",
            className: getSelectedMenuClassName({
              menuPath: [
                "assignment-report-by-subject",
                "assignment-report-by-student",
                "assignment-report-by-teacher",
              ],
            }),
            menu: [
              {
                key: "assignment1",
                content: "Report by subject",
                className: getSelectedMenuClassName({
                  menuPath: "assignment-report-by-subject",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "assignment-report-by-subject",
                    tag: "Subject",
                  });
                },
              },
              {
                key: "assignment2",
                content: "Report by student",
                className: getSelectedMenuClassName({
                  menuPath: "assignment-report-by-student",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "assignment-report-by-student",
                    tag: "Student",
                  });
                },
              },
              {
                key: "assignment3",
                content: "Report by teacher",
                className: getSelectedMenuClassName({
                  menuPath: "assignment-report-by-teacher",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "assignment-report-by-teacher",
                    tag: "Teacher",
                  });
                },
              },
            ],
          },
          {
            key: "event",
            content: "Event",
            on: "hover",
            className: getSelectedMenuClassName({
              menuPath: ["event-report-by-category", "event-report-by-tag"],
            }),
            menu: [
              {
                key: "event1",
                content: "Report by Category",
                className: getSelectedMenuClassName({
                  menuPath: "event-report-by-category",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "event-report-by-category",
                    tag: "Category",
                  });
                },
              },
              {
                key: "event2",
                content: "Report by Tag",
                className: getSelectedMenuClassName({
                  menuPath: "event-report-by-tag",
                }),
                onClick: () => {
                  onDashboardOptionSelection({
                    path: "event-report-by-tag",
                    tag: "Tag",
                  });
                },
              },
            ],
          },
          {
            key: "feedback",
            content: "Feedback",
            className: getSelectedMenuClassName({
              menuPath: "feedback-report",
            }),
            onClick: () => {
              onDashboardOptionSelection({
                path: "feedback-report",
                tag: "Feedback",
              });
            },
          },
        ],
      },
    },
  ];

  return (
    <div className={`Header ${props.user.role}`}>
      <Dialog
        open={openIsModal}
        onConfirm={() => {
          if (pathname === "accountbtn") {
            history.push("/account");
          } else if (pathname === "switchDB") {
            props.switchDb();
            history.push("/switch-db");
          } else if (pathname === "Startup") {
            history.push("/start-up");
          } else if (pathname === "english") {
            setSelLan("en"), i18n.changeLanguage("en");
            setIsOpen(false);
          } else if (pathname === "Español") {
            setSelLan("es"), i18n.changeLanguage("es");
            setIsOpen(false);
          } else if (pathname === "Português") {
            setSelLan("pt"), i18n.changeLanguage("pt");
            setIsOpen(false);
          } else if (pathname === "Français") {
            setSelLan("fr"), i18n.changeLanguage("fr");
            setIsOpen(false);
          } else if (pathname === "Deutsch") {
            setSelLan("de"), i18n.changeLanguage("de");
            setIsOpen(false);
          } else if (pathname === "हिंदी") {
            setSelLan("hi"), i18n.changeLanguage("hi");
            setIsOpen(false);
          }

          setIsOpenModal(false);
        }}
        confirmButton="Okay"
        content={
          "Do you really want change this page? You will lose all the changes that are not saved."
        }
        header={
          pathname === "accountbtn"
            ? "Go to Account page"
            : pathname === "switchDB"
            ? "Go to Switch DB page"
            : pathname === "Startup"
            ? "Go to Start Up page"
            : pathname === "english"
            ? "Switch to English Language"
            : pathname === "Español"
            ? "Switch to Español Language"
            : pathname === "Português"
            ? "Switch to Português Language"
            : pathname === "Français"
            ? "Switch to Français Language"
            : pathname === "Deutsch"
            ? "Switch to Deutsch Language"
            : pathname === "हिंदी"
            ? "Switch to हिंदी Language "
            : null
        }
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => setIsOpenModal(false),
        }}
        styles={{ width: "fit-content !important" }}
      />

      {notification ? (
        notification.status === "Error" ? (
          <Alert severity="error">{notification.msg}</Alert>
        ) : (
          <Alert severity="success">{notification.msg}</Alert>
        )
      ) : (
        ""
      )}

      <Dialog
        onConfirm={() => {
          const email = document.querySelector("#senderEmail").value;
          const name = document.querySelector("#senderName").value;
          const message = document.querySelector("#helpMessage").value;
          const API =
            props.user?.role === "Admin" || props.user?.role === "SuperAdmin"
              ? "/api/school/help-clientAdmin-to-acvAdmin"
              : `/api/school/help-client-to-admin${props.user.slug}`;
          axios
            .post(`${url}${API}`, {
              email: email || "juan.samano@tangibleit.com",
              Name: name,
              Message: message,
              SchoolName:
                props.user?.schoolInfo?.School || "No School Name Provided",
            })
            .then((res) => {
              setMsgSend({
                status: "Success",
                msg: "Mail notification has been send successfully, you will get reply via email.",
              });
              setHelp(false);
              setOpenSnackbar(true);
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
              console.log(err);
              setMsgSend({
                status: "Error",
                msg: "Mail notification couldn't be send. Try to contact Tangible IT Admins.",
              });
            });
        }}
        onCancel={() => setHelp(false)}
        cancelButton="Close"
        confirmButton="Send"
        content={
          <Flex gap="gap.small" column>
            <label htmlFor="senderEmail">Your Email</label>
            <Input
              type="text"
              id="senderEmail"
              placeholder="Enter your email"
              defaultValue={
                props.data?.user?.username
                  ? props.data?.user?.username
                  : props.user?.mail
              }
              showSuccessIndicator
              disabled
              fluid
            />
            <label htmlFor="senderName">Your Name</label>
            <Input
              type="text"
              id="senderName"
              placeholder="Enter your name"
              defaultValue={props.user?.name}
              showSuccessIndicator
              disabled
              fluid
            />
            <label htmlFor="helpMessage">Enter Your Message</label>
            <TextArea
              type="text"
              id="helpMessage"
              placeholder="Type Your Message Here"
              resize="vertical"
              fluid
              height="200"
            />
          </Flex>
        }
        header={
          props.user?.role === "Admin" || props.user?.role === "SuperAdmin"
            ? "Ask to ACV's Admin"
            : "Ask to your Organization's Admin"
        }
        open={help}
      />
      {msgSend ? (
        msgSend.status === "Error" ? (
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Mail notification has been rejected,Please tray again.
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Notification successfully sent, you will get reply via email.
            </Alert>
          </Snackbar>
        )
      ) : (
        ""
      )}

      <Dialog
        open={open}
        onConfirm={() => {
          axios
            .post(`${url}/api/startup/update-DB-data${props.user.slug}`, {
              plan: "Current",
              email: props.data?.user?.username
                ? props.data?.user?.username
                : props.user?.mail,
            })
            .then((res) => {
              props.getDb();
              toast.success("Database has been updated successfully!");
              history.push("/");
              setTimeout(() => {
                setNotification(null);
              }, 3000);
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
              console.log(err);
            });
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
        confirmButton="Yes"
        cancelButton="No"
        content="This action will switch your database to current db, are you sure about switching to your current acv?"
        header="Switch back to own database"
      />

      <Dialog
        content={
          <>
            <Flex column style={{ marginTop: "10px" }}>
              <p>{t("referLink").inviteFriends}</p>
              <Input
                className="email-input"
                fluid
                // icon={<SearchIcon />}
                value={emailInputText}
                onChange={(e, { value }) => {
                  setIsInvalidEmailText(false);
                  setEmailInputText(value);
                }}
                clearable
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onAddEmailHandler();
                  }
                }}
                placeholder="Enter email address"
                error={isInvalidEmailText}
              />

              {isInvalidEmailText ? (
                <p style={{ color: "rgb(196, 49, 75)" }}>
                  {shareFeedbackTranslation.emailValidationError}
                </p>
              ) : null}
              <p style={{ marginTop: !isInvalidEmailText && "1rem" }}>
                {shareFeedbackTranslation.emailAddNote}
              </p>
            </Flex>
            <div className="emails-wrapper" style={{ marginTop: "10px" }}>
              <Flex wrap>
                {(emails.length > 5
                  ? isEmailsExpanded
                    ? emails
                    : emails.slice(0, 5)
                  : emails
                ).map((item) => {
                  return (
                    <Pill
                      actionable
                      onDismiss={() => {
                        setEmails((prev) =>
                          prev.filter((data) => data !== item)
                        );
                      }}
                      style={{
                        minWidth: "initial",
                        backgroundColor: "#6264A7",
                        color: "#fff",
                      }}
                    >
                      {item}
                    </Pill>
                  );
                })}
              </Flex>
              {emails.length > 5 && (
                <Flex hAlign="end">
                  <Button
                    primary
                    onClick={() => setIsEmailsExpanded((prev) => !prev)}
                  >
                    {isEmailsExpanded ? "Show Less" : "Show All"}
                  </Button>
                </Flex>
              )}
            </div>
            <div
              className="action-button-wrapper"
              style={{ marginTop: "10px" }}
            >
              <Flex
                space="between"
                vAlign="center"
                className="feedback-share-action-buttons"
              >
                {/* <ShareButtons
                  link={`https://www.acv.app/prices/${referralCode}`}
                  onClick={copyTextClipboard}
                /> */}
                <Button
                  primary
                  icon={<ShareAltIcon />}
                  content={shareFeedbackTranslation.shareLink}
                  onClick={copyTextClipboard}
                  style={{ backgroundColor: "rgb(196, 49, 75)" }}
                />
                <Button
                  primary
                  disabled={
                    !emails.length ||
                    (loading.type === emailLoading && loading.isLoading)
                  }
                  icon={<UserFriendsIcon />}
                  content={
                    loading.type === emailLoading && loading.isLoading
                      ? commonTranslation.loading
                      : shareFeedbackTranslation.invite
                  }
                  loading={loading.type === emailLoading && loading.isLoading}
                  onClick={sendEmailInvitationHandler}
                />
              </Flex>
            </div>
          </>
        }
        header={t("referLink").shareLink}
        headerAction={{
          icon: <CloseIcon />,
          title: "Close",
          onClick: () => setReferModal(false),
        }}
        onOpen={getToken}
        open={referModal}
        style={{ maxWidth: "600px" }}
      />
      <Flex
        gap="gap.small"
        style={{ height: "60px", backgroundColor: "#F3F2F1" }}
      >
        <Link
          to={props.selectedExtension?.attendance && "/attendance"}
          style={{
            width: window.innerWidth < 930 ? "70px" : "166px",
          }}
        >
          <Image
            src={
              props.user?.dbData?.db_selected === "Trial" &&
              props.user?.role === "Admin"
                ? props.user?.mode === "dark"
                  ? LogoLight
                  : Logo
                : props.user?.schoolInfo?.SchoolLogo
                ? props.user?.schoolInfo?.SchoolLogo + props.user?.SASToken
                : props.user?.mode === "dark"
                ? LogoLight
                : Logo
            }
            style={{
              margin: 0,
              marginLeft: "31px",
              marginTop: "10px",
              maxHeight: "40px",
              borderRadius: "2px",
              backgroundSize: "cover",
            }}
            title={props.user?.schoolInfo?.School || "Tangible IT"}
          />
        </Link>
        <>
          <Flex
            gap="gap.medium"
            style={{ paddingBlock: "20px" }}
            className="ctr-right"
          >
            {props.user?.role === "Admin" && (
              <Flex.Item>
                <Button
                  icon={<ContentIcon outline size="large" />}
                  content={t("startUp").initial[1]}
                  className="btn"
                  style={{
                    textDecoration: "none",
                    color: "#666",
                    fontSize: "14px",
                    fontWeight: "600",
                    border: "none",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    paddingLeft: "0px",
                    paddingRight: "14px",
                    pointerEvents: "none",
                    height: "100%",
                  }}
                />
              </Flex.Item>
            )}
            {isMobileView && props.selectedReportPath && (
              <Icon.Filter
                size="20"
                className="ctrl"
                onClick={() => {
                  openModal();
                }}
              />
            )}
            <MenuIcon
              outline
              size="large"
              className="Ham ctrl"
              onClick={() => setHam(!ham)}
            />
          </Flex>

          <div className={ham ? "ham_container active" : "ham_container"}>
            <div className="cover">
              <Flex column>
                {props.user &&
                  ["Admin", "SuperAdmin", "Teacher"].includes(
                    props.user?.role
                  ) && (
                    <Segment
                      content={
                        <Layout
                          gap="1rem"
                          vertical={false}
                          start={<ContactGroupIcon outline size="large" />}
                          main="Attendance"
                          end=""
                          style={{
                            color:
                              props.path === "attendance"
                                ? "#68689E"
                                : "#544F53",
                          }}
                          onClick={() => {
                            history.push("/attendance");
                            setHam(!ham);
                          }}
                        />
                      }
                    />
                  )}
                <Segment
                  content={
                    <Layout
                      gap="1rem"
                      vertical={false}
                      start={<ContactGroupIcon outline size="large" />}
                      main="Assignments"
                      end=""
                      style={{
                        color:
                          props.path === "assignments" ? "#68689E" : "#544F53",
                      }}
                      onClick={() => {
                        history.push("/assignments");
                        setHam(!ham);
                      }}
                    />
                  }
                />

                {props.user &&
                  ["Admin", "SuperAdmin", "Teacher", "Student"].includes(
                    props.user?.role
                  ) && (
                    <Segment
                      content={
                        <Layout
                          gap="1rem"
                          vertical={false}
                          start={<PollIcon outline size="large" />}
                          main="Dashboard"
                          end=""
                          style={{
                            color: location.pathname?.includes("dashboard")
                              ? "#68689E"
                              : "#544F53",
                          }}
                          onClick={() => {
                            history.push("/dashboard");
                            setHam(!ham);
                          }}
                        />
                      }
                    />
                  )}
                {props.user &&
                  ["Admin", "SuperAdmin", "Teacher", "Student"].includes(
                    props.user?.role
                  ) && (
                    <Segment
                      content={
                        <Layout
                          gap="1rem"
                          vertical={false}
                          start={<WhiteboardIcon outline size="large" />}
                          main="Whiteboard"
                          end=""
                          style={{
                            color:
                              props.path === "whiteboard"
                                ? "#68689E"
                                : "#544F53",
                          }}
                          onClick={() => {
                            history.push("/whiteboard");
                            setHam(!ham);
                          }}
                        />
                      }
                    />
                  )}
                <Segment
                  content={
                    <Layout
                      gap="1rem"
                      vertical={false}
                      start={<CalendarIcon outline size="large" />}
                      main="Events"
                      end=""
                      style={{
                        color: props.path === "events" ? "#68689E" : "#544F53",
                      }}
                      onClick={() => {
                        history.push("/events/my-events");
                        setHam(!ham);
                      }}
                    />
                  }
                />
                {!["admin", "superadmin", "parent"].includes(
                  props?.user?.role.toLowerCase()
                ) ? (
                  <Segment
                    content={
                      <Layout
                        gap="1rem"
                        vertical={false}
                        start={<CalendarIcon outline size="large" />}
                        main="Booking"
                        end=""
                        style={{
                          color:
                            props.path === "booking" ? "#68689E" : "#544F53",
                        }}
                        onClick={() => {
                          history.push("/booking");
                        }}
                      />
                    }
                  />
                ) : null}
                {props.user &&
                  ["Admin", "SuperAdmin", "Teacher"].includes(
                    props.user?.role
                  ) && (
                    <Segment
                      content={
                        <Layout
                          gap="1rem"
                          vertical={false}
                          start={<CheckmarkCircleIcon outline size="large" />}
                          main={"Attendance History"}
                          end=""
                          style={{
                            color:
                              props.path === "history" ? "#68689E" : "#544F53",
                          }}
                          onClick={() => {
                            history.push("/history");
                            setHam(!ham);
                          }}
                        />
                      }
                    />
                  )}
                <Segment
                  content={
                    <Layout
                      gap="1rem"
                      vertical={false}
                      start={<ContactGroupIcon outline size="large" />}
                      main="Feedback"
                      end=""
                      style={{
                        color:
                          props.path === "feedback" ? "#68689E" : "#544F53",
                      }}
                      onClick={() => {
                        history.push("/feedback/submit-feedback");
                        setHam(!ham);
                      }}
                    />
                  }
                />
                {props.user &&
                  ["Admin", "SuperAdmin"].includes(props.user?.role) && (
                    <Segment
                      content={
                        <Layout
                          gap="1rem"
                          vertical={false}
                          start={<SettingsIcon outline size="large" />}
                          main="Settings"
                          end=""
                          style={{
                            color:
                              props.path === "settings" ? "#68689E" : "#544F53",
                          }}
                          onClick={() => {
                            history.push("/settings");
                            setHam(!ham);
                          }}
                        />
                      }
                    />
                  )}

                {props.selectedExtension?.aiTutor &&
                  !["parent", "externaltutor"].includes(
                    props.user?.role.toLowerCase()
                  ) && (
                    <Segment
                      content={
                        <Layout
                          gap="1rem"
                          vertical={false}
                          start={<ContactGroupIcon outline size="large" />}
                          main="AiTutor"
                          end=""
                          style={{
                            color:
                              props.path === "ai-tutor" ? "#68689E" : "#544F53",
                          }}
                          onClick={() => {
                            history.push("/ai-tutor");
                            setHam(!ham);
                          }}
                        />
                      }
                    />
                  )}
                <Divider className="ham-dv" />
                <Segment
                  content={
                    <Layout
                      gap="0rem"
                      vertical={false}
                      main={profileButton}
                      style={{
                        color: "#544F53",
                      }}
                    />
                  }
                />
              </Flex>
            </div>
          </div>

          <nav style={{ paddingTop: "17px", display: "flex" }}>
            {props?.user?.role.toLowerCase() === "parent" ? (
              <Link
                to={"/parent/student"}
                className={
                  props.path === "student" ? "activeBtn activeTab" : "button"
                }
              >
                Student
              </Link>
            ) : null}
            {["Admin", "SuperAdmin", "Teacher"].includes(props.user?.role) &&
            props.selectedExtension?.attendance ? (
              <Link
                to={"/attendance"}
                className={
                  props.path === "attendance" ? "activeBtn activeTab" : "button"
                }
              >
                Attendance
              </Link>
            ) : null}
            {props.selectedExtension?.assignment &&
            !["parent", "externaltutor"].includes(
              props.user?.role.toLowerCase()
            ) ? (
              <Link
                to={"/assignments"}
                className={
                  props.path === "assignments"
                    ? "activeBtn activeTab"
                    : "button"
                }
              >
                Assignments
              </Link>
            ) : null}

            {props.user &&
            ["Admin", "SuperAdmin", "Teacher", "Student"].includes(
              props.user?.role
            ) &&
            props.selectedExtension?.dashboard ? (
              <Link
                to={"/dashboard"}
                className={
                  location.pathname?.includes("dashboard")
                    ? "activeBtn activeTab"
                    : "button"
                }
              >
                Dashboard
              </Link>
            ) : null}
            {props.user &&
            ["Admin", "SuperAdmin", "Teacher", "Student"].includes(
              props.user?.role
            ) &&
            props.selectedExtension?.whiteBoard ? (
              <Link
                to={"/whiteboard"}
                className={
                  props.path === "whiteboard" ? "activeBtn activeTab" : "button"
                }
              >
                Whiteboard
              </Link>
            ) : null}
            {props.user &&
            ["Admin", "SuperAdmin", "Teacher", "Student", "Parent"].includes(
              props.user?.role
            ) &&
            props.selectedExtension?.events ? (
              <Link
                to={
                  props.user.role === "Parent"
                    ? "/events/discover"
                    : "/events/my-events"
                }
                className={
                  props.path === "events" ? "activeBtn activeTab" : "button"
                }
              >
                Events
              </Link>
            ) : null}
            {(!["admin", "superadmin", "parent"].includes(
              props?.user?.role.toLowerCase()
            ) ||
              props?.user?.isTutor) &&
            props.selectedExtension?.booking ? (
              <Link
                to={"/booking"}
                className={
                  props.path === "booking" ? "activeBtn activeTab" : "button"
                }
              >
                Booking
              </Link>
            ) : null}
            {!["externaltutor"].includes(props?.user?.role.toLowerCase()) &&
            props.selectedExtension?.feedback ? (
              <Link
                to={"/feedback/submit-feedback"}
                className={
                  props.path === "feedback" ? "activeBtn activeTab" : "button"
                }
              >
                Feedback
              </Link>
            ) : null}

            {props.user &&
              ["Admin", "SuperAdmin"].includes(props.user?.role) && (
                <Link
                  to={"/settings"}
                  className={
                    props.path === "settings" ? "activeBtn activeTab" : "button"
                  }
                >
                  Settings
                </Link>
              )}

            {props.selectedExtension?.aiTutor &&
              !["parent", "externaltutor"].includes(
                props.user?.role.toLowerCase()
              ) && (
                <Link
                  to={"/ai-tutor"}
                  className={
                    props.path === "ai-tutor" ? "activeBtn activeTab" : "button"
                  }
                >
                  AiTutor
                </Link>
              )}

            <Button
              text
              circular
              icon={isFullscreen ? <CollapseIcon /> : <ExpandIcon />}
              onClick={isFullscreen ? closeFullscreen : openFullscreen}
              title={isFullscreen ? "Close Full Screen" : "Full Screen"}
              style={{
                marginLeft: "12px",
                minWidth: "2rem",
                height: "auto",
                display: "block",
              }}
            />
          </nav>

          <Flex className="rightNav" gap="gap.small" hAlign="end">
            {props.user?.role === "Admin" && (
              <Button
                icon={<ContentIcon outline size="large" />}
                content={
                  window.innerWidth > 1100 ? t("startUp").initial[1] : ""
                }
                className="btn"
                iconOnly={window.innerWidth < 1100}
                style={{
                  textDecoration: "none",
                  color: "#666",
                  fontSize: "14px",
                  fontWeight: "600",
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  paddingLeft: "0px",
                  pointerEvents: "none",
                }}
              />
            )}
            {(props.path === "whiteboard" ||
              props.path === "attendance" ||
              props.path === "feedback" ||
              props.path === "events") && (
              <Icon.Filter
                size="20"
                className="ctrl mt-2 "
                onClick={() => {
                  if (document.querySelector(".h-ctr")) {
                    document.querySelector(".h-ctr").classList.add("s-ctr");
                    document.querySelector(".h-ctr").classList.remove("h-ctr");
                  } else if (document.querySelector(".s-ctr")) {
                    document.querySelector(".s-ctr").classList.add("h-ctr");
                    document.querySelector(".s-ctr").classList.remove("s-ctr");
                  }
                }}
              />
            )}

            {["attendance", "history"].includes(props.path) && (
              <Link
                to="/history"
                className={props.path === "history" ? "activeTab " : "button "}
                style={{ marginLeft: 0 }}
              >
                <AttendeeIcon style={{ marginRight: "8px" }} />
                {window.innerWidth > 1100 ? "Attendance History" : ""}
              </Link>
            )}

            {profileButton}
          </Flex>
        </>
      </Flex>
    </div>
  );
};

export default Header;
